
import { ref, defineComponent } from 'vue'
import * as api from '../utils/api'
import Hospital from '../components/Hospital.vue'
import Tokyo from '../components/Tokyo.vue'
import Header from '../components/menu/Public.vue'
import { useDialog } from 'naive-ui'
import { IAPIReturnWithReview } from '../../interfaces/hospital'
import { workTypes, prefectures, tags, tagCats } from '../utils/const'
import { TropicalWarning, SatelliteRadar } from '@vicons/carbon'
import Q from 'q'

export default defineComponent({
	components: {
		Hospital,
		Header,
		TropicalWarning,
		SatelliteRadar,
		Tokyo,
	},
	data() {
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
		const modelRef = ref({
			area: [] as string[],
			type: 'veterinarian',
			freeWord: '',
			tags: [] as string[],
			page: ref(1),
		})
		return {
			model: modelRef,
			errorDialog,
			logined: api.isLoginned(),
			loading: false,
			initiated: true,
			tags: tags(),
			tagCats,
			mobile,
			workTypes,
			prefectures,
			count: 0,
			maxPage: 1,
			applys: [] as IAPIReturnWithReview[],
		}
	},
	mounted: function () {
		this.init()
	},
	methods: {
		init: function () {
			const obj: any = location.search
				.slice(1)
				.split('&')
				.map((p) => p.split('='))
				.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
			if (obj.freeWord) this.model.freeWord = decodeURIComponent(obj.freeWord)
			if (obj.type) this.model.type = obj.type
			if (obj.area) this.model.area = [obj.area]
			if (obj.tag) this.model.tags = decodeURIComponent(obj.tag).split(',')
			if (obj.freeWord || obj.type || obj.area || obj.tag) this.q()
		},
		q: async function () {
			try {
				const param: any = JSON.parse(JSON.stringify(this.model))
				param.page = typeof param.page === 'number' ? param.page : 1
				this.loading = true
				this.initiated = false
				const data = await api.postAsLogined('/v1/hospital', param)
				this.count = data.count
				this.loading = false
				this.applys = data.data
				this.maxPage = Math.floor(data.count / 20 + 1)
				const interval = setInterval(function () {
					const element = document.getElementById('result')
					if (element) clearInterval(interval)
					const rect = element?.getBoundingClientRect()
					if (!rect) return
					window.scroll({
						top: rect.top,
						behavior: 'smooth',
					})
				}, 100)
			} catch (e: any) {
				this.loading = false
				this.errorDialog(e, () => true)
			}
		},
		getTags: function (tags: string[]) {
			if (!tags.length) return '　'
			return tags.map((v) => `#${v}`).join(' ')
		},
		regionSelect: function (region: string) {
			this.model.area.push(region)
		},
		pager: function (i: number) {
			this.model.page = i
			this.q()
		},
	},
})
