
import { defineComponent } from 'vue'
/*
@click="func('westTama')" @mouseover="hover('多摩西部')" @mouseleave="leave()"
                    */
export default defineComponent({
	props: ['func'],
	data() {
		return {
			hoverItem: '',
		}
	},
	methods: {
		select: function (region: string) {
			this.func(region)
		},
		hover: function (region: string) {
			this.hoverItem = region
		},
        leave: function () {
            this.hoverItem = ''
        }
	},
})
